@font-face {
  font-family: Arial, Helvetica, sans-serif;
}
/* @tailwind base; */
/* @tailwind components; */
/* @tailwind utilities; */

body {
  margin: 0;
  padding: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
